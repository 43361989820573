import { UserProfile, RoleEnum } from 'app/models';
import { push as pushAction } from 'connected-react-router';
import { SchemeProps } from 'app/containers/Builder/props';
import { Dispatch, bindActionCreators } from 'redux';
import * as Icons from 'app/components/Icons';
import { UserApiModel } from 'app/api/types';
import Builder from 'app/containers/Builder';
import Spinner from 'react-loader-spinner';
import { UserActions } from 'app/actions';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { toast } from 'react-toastify';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import * as schemes from '../schemes';
import * as api from 'app/api';
import * as React from 'react';
import _ from 'lodash';
import Checkbox from 'app/sharedComponents/Checkbox';

import FileUpload from 'app/sharedComponents/FileUpload';

const docTypes = ['BANK', 'OGRN', 'INN', 'OTHER'] as const;

type DocType = (typeof docTypes)[number];

const documentTypeValues: Record<DocType, string> = {
	OTHER: 'profile-other',
	BANK: 'profile-bank',
	OGRN: 'profile-ogrn',
	INN: 'profile-inn',
};

const profileCertificatesReducer = (acc: any, item: any) => {
	const newItem = { ...item, preview: item.document };
	return acc[item.kind]
		? {
				...acc,
				[item.kind]: [...acc[item.kind], newItem],
		  }
		: {
				...acc,
				[item.kind]: [newItem],
		  };
};

const certificatesDefaultState = docTypes.reduce((acc, item) => {
	return {
		...acc,
		[item]: [],
	};
}, {});

class DocsProfileForm extends React.Component<DocsProfileForm.Props, DocsProfileForm.State> {
	public builderDriverLicence: Builder | null = null;
	public builderBankAccount: Builder | null = null;
	public builderPassport: Builder | null = null;

	public state: DocsProfileForm.State = {
		interestedInDigitalSignature: false,

		driverLicenceScheme: _.clone(schemes.driverLicence),
		passportScheme: _.cloneDeep(schemes.passport),
		bankScheme: _.cloneDeep(schemes.company),

		driverLicenceForm: {},
		bankAccountForm: {},
		passportForm: {},

		loading: false,
		valid: false,
	};

	constructor(props: DocsProfileForm.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.getDigitalSignature = this.getDigitalSignature.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.isValid = this.isValid.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	public componentDidMount() {
		const { builderDriverLicence, builderPassport, builderBankAccount } = this;
		const { profile } = this.props;

		if (
			// Check initialized builders
			builderDriverLicence &&
			builderBankAccount &&
			builderPassport &&
			// Check role
			profile &&
			profile.driverDocs &&
			profile.role === RoleEnum.Driver
		) {
			const {
				driverDocs: { passport, driverLicence, bankAccount },
			} = profile;

			Object.keys(passport).forEach((key) => {
				const ref = builderPassport.formRefs.get(key);
				const value = (passport as any)[key];

				if (ref && value) {
					ref.updateValue(value);
				}
			});

			Object.keys(driverLicence ? driverLicence : {}).forEach((key) => {
				const ref = builderDriverLicence.formRefs.get(key);
				const value = (driverLicence as any)[key];

				if (ref && value) {
					ref.updateValue(value);
				}
			});

			Object.keys(bankAccount ? bankAccount : {}).forEach((key) => {
				const ref = builderBankAccount.formRefs.get(key);
				const value = (bankAccount as any)[key];

				if (ref && value) {
					ref.updateValue(value);
				}
			});
		}
	}

	private onUpdate(docsType: 'passport' | 'driverLicence' | 'bankAccount') {
		return (name: string, value: any) => {
			const formKey = `${docsType}Form`;
			const form = (this.state as any)[formKey];

			this.setState({
				[formKey]: {
					...form,
					[name]: value,
				},
			} as any);
		};
	}

	private isValid(docsType: 'passport' | 'driverLicence' | 'bankAccount') {
		const { passportForm, driverLicenceForm, bankAccountForm } = this.state;
		const { profile } = this.props;

		if (profile && profile.driverDocs && profile.role === RoleEnum.Driver) {
			const { passport, driverLicence } = profile.driverDocs;

			switch (docsType) {
				case 'passport':
					return (
						passportForm.issuedAt &&
						passportForm.issuedAt.length > 0 &&
						passportForm.number &&
						passportForm.number.length > 0 &&
						passportForm.issuer &&
						passportForm.issuer.length > 0 &&
						(!!passportForm.registrationPage || !!passport.registrationPage) &&
						(!!passportForm.mainPage || !!passport.mainPage)
					);

				case 'driverLicence':
					return (
						driverLicenceForm.expiredAt &&
						driverLicenceForm.expiredAt.length > 0 &&
						driverLicenceForm.issuedAt &&
						driverLicenceForm.issuedAt.length > 0 &&
						driverLicenceForm.number &&
						driverLicenceForm.number.length > 0 &&
						(!!driverLicenceForm.mainPage || !!driverLicence.mainPage) &&
						(!!driverLicenceForm.backPage || !!driverLicence.backPage)
					);

				case 'bankAccount':
					return (
						bankAccountForm.account &&
						bankAccountForm.account.length === 20 &&
						bankAccountForm.bic &&
						bankAccountForm.bic.length === 9 &&
						bankAccountForm.bic.substr(0, 2) === '04'
					);

				default:
					return false;
			}
		} else {
			return false;
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private async onSave() {
		const { sessionToken, setProfile, updateDocs, profile } = this.props;

		const { interestedInDigitalSignature, driverLicenceForm, bankAccountForm, passportForm, loading } = this.state;

		if (sessionToken && setProfile && updateDocs && !loading && profile) {
			const updated: UserActions.Payload.Docs = {};

			const failed: [string, Error][] = [];
			const success: string[] = [];

			this.setState({ loading: true });

			if (interestedInDigitalSignature) {
				// @TODO: fix that shit api call in back issue
				const paramsProfile = {
					interestedInDigitalSignature,

					companyName: profile.companyName,
					middleName: profile.middleName,
					firstName: profile.firstName,
					legalType: profile.legalType,
					lastName: profile.lastName,
					email: profile.email,
					inn: profile.inn,
				};

				api
					.userUpdateProfile(paramsProfile)
					.then((profile) => {
						setProfile(profile);
					})
					.catch((error) => {
						console.error(error);
					});
			}

			await Promise.all([
				api
					.userUpdatePassport({ ...passportForm })
					.then((passport) => {
						success.push('profile-header-passport');
						updated.passport = passport;
					})
					.catch((error) => {
						failed.push(['profile-header-passport', error]);
					}),

				api
					.userUpdateBankAccount({ ...(bankAccountForm as any) })
					.then((bankAccount) => {
						success.push('profile-org-header');
						updated.bankAccount = bankAccount;
					})
					.catch((error) => {
						failed.push(['profile-org-header', error]);
					}),

				api
					.userUpdateDriverLicence({ ...driverLicenceForm })
					.then((driverLicence) => {
						success.push('profile-header-driver-licence');
						updated.driverLicence = driverLicence;
					})
					.catch((error) => {
						failed.push(['profile-header-driver-licence', error]);
					}),
			]);

			if (updateDocs) {
				updateDocs(updated);
			}

			this.setState({ loading: false });

			if (failed.length > 0) {
				failed.forEach(([error]) => {
					console.error(error);
				});

				const failedMessages = failed.map(([message]) => this.getLocalizedMessage(message));
				const errorMessage = `${this.getLocalizedMessage('profile-alert-docs-updated-failed')} ${failedMessages.join(
					', ',
				)}`;

				toast.error(errorMessage, {
					autoClose: 5000,
					position: toast.POSITION.BOTTOM_CENTER,
				});
			} else {
				toast.success(this.getLocalizedMessage('profile-alert-updated'), {
					position: toast.POSITION.BOTTOM_CENTER,
				});

				const driverLicenceValid = this.isValid('driverLicence');
				const bankAccount = this.isValid('bankAccount');
				const passportValid = this.isValid('passport');

				if (passportValid && driverLicenceValid && bankAccount) {
					this.props.toggleEditable(false);
					// Show modal if all data approved
					this.props.openSendedModal();
				}
			}
		}
	}

	private getDigitalSignature() {
		const { interestedInDigitalSignature } = this.state;
		const { profile } = this.props;

		const onChange = ({ target: { checked } }: any) => {
			this.setState({
				interestedInDigitalSignature: checked,
			});
		};

		return profile && profile.interestedInDigitalSignature ? (
			<div className='alert panel'>
				<div className='icon'>
					<Icons.Check color='#AEB4C8' />
				</div>
				<div className='title'>
					<Localized id='profile-digital-signature-requested' />
				</div>
			</div>
		) : (
			<div className='need-digital-signature'>
				<Checkbox checked={interestedInDigitalSignature} onChange={onChange}>
					<Localized id='profile-digital-signature-needed' />
				</Checkbox>

				<a className='action' target='_blank' href='/panel/faq/verify'>
					<Localized id='faq-button-verify-text' />
				</a>
			</div>
		);
	}

	private outputModeratorMessages(builder?: Builder | null, key?: string) {
		const { profile } = this.props;
		if (!profile || !key) return;
		const driverDocs: any = profile.driverDocs;
		if (builder) {
			const doc = driverDocs[key];
			if (!doc) return;
			builder.outputOperatorCommentary(doc.verification);
		}
	}
	uploadCertificate = (kind: string) => async (document: any) => {
		const { addUserCertificate, sessionToken } = this.props;

		try {
			if (addUserCertificate && sessionToken) {
				const certificate = await api.userCertificate({ document }, 'post', kind);
				addUserCertificate(certificate);
			}
		} catch (error) {
			console.error(error);
		}

		return Promise.resolve();
	};

	deleteCertificate = async (document: any) => {
		const { deleteUserCertificate } = this.props;
		try {
			await api.userDeleteCertificate(document.id);
			if (deleteUserCertificate) {
				deleteUserCertificate(document.id);
			}
		} catch (error) {
			console.log(error);
		}
	};

	public render() {
		const { passportScheme, driverLicenceScheme, bankScheme, loading } = this.state;
		const loadingClass = loading ? 'loading' : '';
		const { profile } = this.props;
		//@ts-ignore
		const { driverDocs } = profile;

		const passportBuilderProps = {
			getRef: (node: Builder | null) => {
				this.builderPassport = node;
				this.outputModeratorMessages(node, 'passport');
			},
			onUpdate: this.onUpdate('passport'),
			scheme: passportScheme.map((el: any) => ({
				...el,
				disabled: driverDocs?.passport?.verification?.status === 'verified',
			})),
		};

		const driverLicenceBuilderProps = {
			getRef: (node: Builder | null) => {
				this.builderDriverLicence = node;
				this.outputModeratorMessages(node, 'driverLicence');
			},
			onUpdate: this.onUpdate('driverLicence'),
			scheme: driverLicenceScheme.map((el: any) => ({
				...el,
				disabled: driverDocs?.driverLicence?.verification?.status === 'verified',
			})),
		};

		const bankAccountBuilderProps = {
			getRef: (node: Builder | null) => {
				this.builderBankAccount = node;
			},
			onUpdate: this.onUpdate('bankAccount'),
			scheme: bankScheme.map((el: any) => ({ ...el, disabled: profile?.verification?.status === 'verified' })),
		};

		//@ts-ignore
		const certificates = Object.values(profile?.certificates).reduce(
			profileCertificatesReducer,
			certificatesDefaultState,
		);

		return (
			<div className='profile docs form'>
				<div className='profile__verify-icon'>
					<Icons.VerifyUser />
				</div>

				<div className='common'>
					<div className='header'>
						<div className='title'>
							<Localized id='profile-docs-header' />
						</div>

						<div className='subtitle'>
							<Localized id='profile-docs-description' />
						</div>

						<a className='action' target='_blank' href='/panel/faq/verify'>
							<Localized id='faq-button-verify-text' />
						</a>
					</div>

					<Builder {...passportBuilderProps} />
					<Builder {...driverLicenceBuilderProps} />

					<Builder {...bankAccountBuilderProps} />

					{docTypes.map((key) => (
						<FileUpload
							title={<Localized id={documentTypeValues[key]} />}
							key={key}
							onDelete={this.deleteCertificate}
							uploaded={certificates[key]}
							onUpload={this.uploadCertificate(key)}
						/>
					))}

					{this.getDigitalSignature()}

					<div className='actions'>
						<div className={`action ${loadingClass}`} onClick={this.onSave}>
							{loading ? (
								<>
									<Spinner type='RevolvingDot' color='#1FB0E2' height={22} width={22} />

									<Localized id='profile-action-save-loading' />
								</>
							) : (
								<Localized id='profile-action-save' />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ user, driver, common }: RootState) => ({
	sessionToken: user.sessionToken,
	locales: common.bundlesLocales,
	profile: user.profile,
	car: driver.carOpened,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateDocs: UserActions.updateDocs,
			setProfile: UserActions.setProfile,
			push: pushAction,
			addUserCertificate: UserActions.addUserCertificate,
			deleteUserCertificate: UserActions.deleteUserCertificate,
		},
		dispatch,
	);

export default connect<DocsProfileForm.StateProps, DocsProfileForm.DispatchProps, DocsProfileForm.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(DocsProfileForm);

namespace DocsProfileForm {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		profile?: UserProfile;
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		setProfile?: (profile: UserProfile | UserApiModel.UserProfile) => void;
		updateDocs?: (docs: UserActions.Payload.Docs) => void;
		push?: (path: string, state?: any) => void;
		addUserCertificate?: (file: any) => void;
		deleteUserCertificate?: (id: string) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		toggleEditable: (editable: boolean) => void;
		openSendedModal: () => void;
	}

	// Main component state
	export interface State {
		interestedInDigitalSignature: boolean;

		driverLicenceForm: { [name: string]: any };
		bankAccountForm: { [name: string]: any };
		passportForm: { [name: string]: any };

		driverLicenceScheme: SchemeProps.Union[];
		passportScheme: SchemeProps.Union[];
		bankScheme: SchemeProps.Union[];

		loading: boolean;
		valid: boolean;
	}
}
