import { RootState } from 'app/reducers/state';

import { executorReducer } from './executor';
import { commonReducer } from './common';
import { userReducer } from './user';
import { bidReducer } from './bid';
import { candidatesReducer } from './candidates';
import driver from './driver/index';
import { transportReducer } from './transport';
import { subscriptionsReducer } from './subscriptions';
import { vacantExecutorsReducer } from './executors';
import { customerReducer } from './customer';
import { filterReducer } from './filters';
import { transportOnMapReducer } from './transportOnMap';
import customerTransportReducer from './customerTransport';
import { pricesReducer } from './prices';
import { bidPointsReducer } from './bidsPoints';
import { documentsReducer } from './documents';

export const reducers = {
	candidates: candidatesReducer,
	executors: executorReducer as any,
	driver,
	common: commonReducer as any,
	user: userReducer as any,
	bid: bidReducer as any,
	bidPoints: bidPointsReducer as any,
	transport: transportReducer as any,
	transportOnMap: transportOnMapReducer as any,
	subscriptions: subscriptionsReducer as any,
	vacantExecutors: vacantExecutorsReducer as any,
	customer: customerReducer as any,
	filters: filterReducer as any,
	customerTransport: customerTransportReducer as any,
	prices: pricesReducer as any,
	documents: documentsReducer as any,
};

export { RootState };
