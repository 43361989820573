import { Action, handleActions } from 'redux-actions';
import { RootState } from 'app/reducers/state';
import { UserApiModel } from 'app/api/types';
import { UserActions } from 'app/actions';

import { UserVerifyStatus, UserSettings, UserProfile, UserContact, UserCertificate } from 'app/models';

const initialSessionToken = (): string | undefined => {
	const token = localStorage.getItem('sessionToken');
	return token ? token : undefined;
};

const initialUserProfile = (): UserProfile | undefined => {
	const profile = localStorage.getItem('profile');
	return profile ? (JSON.parse(profile) as UserProfile) : undefined;
};

const initialUserContacts = (): UserContact[] | undefined => {
	const contacts = localStorage.getItem('contacts');
	return contacts ? (JSON.parse(contacts) as UserContact[]) : undefined;
};

const initialUserVerify = (): UserVerifyStatus => {
	const status = localStorage.getItem('verifyStatus');
	return status ? (JSON.parse(status) as UserVerifyStatus) : {};
};

const initialUserSettings = (): UserSettings | undefined => {
	const settings = localStorage.getItem('settings');
	return settings ? (JSON.parse(settings) as UserSettings) : undefined;
};

export const initialState: RootState.User = {
	sessionToken: initialSessionToken(),
	verifyStatus: initialUserVerify(),
	contacts: initialUserContacts(),
	settings: initialUserSettings(),
	profile: initialUserProfile(),
};

export const userReducer = handleActions<RootState.User, any>(
	{
		[UserActions.Action.SET_SESSION_TOKEN]: (state, { payload }: Action<string>) => {
			if (payload) {
				localStorage.setItem('sessionToken', payload);

				return {
					...state,
					sessionToken: payload,
				};
			} else {
				return {
					...state,
				};
			}
		},

		[UserActions.Action.USER_UPDATE_DOCS]: (state, { payload }: Action<UserActions.Payload.Docs>) => ({
			...state,

			...(payload && state.profile
				? {
						profile: {
							...state.profile,
							driverDocs: {
								...(state.profile.driverDocs ? state.profile.driverDocs : {}),
								...payload,
							} as any,
						},
				  }
				: {}),
		}),

		[UserActions.Action.SET_USER_PROFILE]: (state, { payload }: Action<UserProfile | UserApiModel.UserProfile>) => {
			if (state.profile && payload) {
				const profile = {
					...state.profile,
					...payload,
				};

				localStorage.setItem('profile', JSON.stringify(profile));

				return {
					...state,
					profile,
				};
			} else if (!state.profile && payload) {
				localStorage.setItem('profile', JSON.stringify(payload));

				return {
					...state,
					profile: payload as UserProfile,
				};
			} else {
				console.warn('You call `SET_USER_PROFILE` action without payload');

				return {
					...state,
				};
			}
		},

		[UserActions.Action.SET_USER_SETTINGS]: (state, { payload }: Action<UserSettings>) => {
			if (payload) {
				localStorage.setItem('settings', JSON.stringify(payload));
			}

			return {
				...state,
				settings: payload,
			};
		},

		[UserActions.Action.SET_VERIFY_STATUS]: (state, { payload }: Action<UserActions.Payload.Verify>) => {
			let verifyStatus = { ...state.verifyStatus };

			if (payload) {
				verifyStatus = {
					...verifyStatus,
					[payload.type]: payload.value,
				};

				localStorage.setItem('verifyStatus', JSON.stringify(verifyStatus));
			}

			return {
				...state,
				verifyStatus,
			};
		},

		[UserActions.Action.DELETE_USER_CERTIFICATE]: (state, { payload }: Action<string>) => {
			const certificates = state.profile && state.profile.certificates;
			if (certificates && payload) {
				delete certificates[payload];
			}

			return {
				...state,
				...(state.profile
					? {
							profile: {
								...state.profile,
								certificates: { ...certificates },
							},
					  }
					: {}),
			};
		},

		[UserActions.Action.ADD_USER_CERTIFICATE]: (state, { payload }: Action<UserCertificate>) => ({
			...state,

			...(payload && state.profile && state.profile.certificates
				? {
						profile: {
							...state.profile,

							certificates: {
								...state.profile.certificates,
								[payload.uuid]: payload,
							},
						},
				  }
				: {}),
		}),

		[UserActions.Action.SET_USER_AVATAR_URL]: (state, { payload }: Action<UserActions.Payload.Avatar>) => {
			const profileItem = localStorage.getItem('profile');

			// Save updated to localStorage
			if (profileItem && payload) {
				let profile = JSON.parse(profileItem) as UserProfile;

				profile = {
					...profile,
					...payload,
				};

				localStorage.setItem('profile', JSON.stringify(profile));
			}

			if (state.profile && payload) {
				const profile = {
					...state.profile,
					...payload,
				};

				return {
					...state,
					profile,
				};
			} else {
				return {
					...state,
				};
			}
		},

		[UserActions.Action.SET_USER_CONTACTS]: (state, { payload }: Action<UserContact[]>) => {
			// Save to localStorage
			if (payload) {
				localStorage.setItem('contacts', JSON.stringify(payload));
			}

			return {
				...state,

				...(!payload
					? {}
					: {
							contacts: payload,
					  }),
			};
		},

		[UserActions.Action.CREATE_CONTACT]: (state, { payload }: Action<UserContact>) => {
			const contacts = payload && state.contacts ? [...state.contacts, payload] : state.contacts;

			localStorage.setItem('contacts', JSON.stringify(contacts));

			return {
				...state,
				contacts,
			};
		},

		[UserActions.Action.UPDATE_CONTACT]: (state, { payload }: Action<UserContact>) => {
			const contacts =
				payload && state.contacts
					? state.contacts.map((contact) => (contact.uuid === payload.uuid ? { ...contact, ...payload } : contact))
					: state.contacts;

			localStorage.setItem('contacts', JSON.stringify(contacts));

			return {
				...state,
				contacts,
			};
		},

		[UserActions.Action.DELETE_CONTACT]: (state, { payload }: Action<string>) => {
			const contacts =
				payload && state.contacts ? state.contacts.filter((contact) => contact.uuid !== payload) : state.contacts;

			localStorage.setItem('contacts', JSON.stringify(contacts));

			return {
				...state,
				contacts,
			};
		},

		[UserActions.Action.SET_TMP_CONTACT]: (state, { payload }: Action<UserContact>) => {
			return {
				...state,
				tmpContact: payload,
			};
		},

		[UserActions.Action.USER_LOGOUT]: () => {
			localStorage.removeItem('sessionToken');
			localStorage.removeItem('verifyStatus');
			localStorage.removeItem('contacts');
			localStorage.removeItem('settings');
			localStorage.removeItem('profile');

			return {
				verifyStatus: {},
			};
		},
	},
	initialState,
);
